/* eslint no-console:0 */

// Bootstrap and application styles
import "bootstrap";
import "@fortawesome/fontawesome-pro/css/all";
import "./application.scss";
import "styles/prospect_flatpickr.scss";

// Images
require.context("../images", true);

// Rails support
import Rails from "@rails/ujs";
Rails.start();

// TODO: Add our own code to handle this
// Custom modal styling
// BS5 discussion: https://github.com/ifad/data-confirm-modal/issues/83
require("../vendor/data-confirm-modal");
dataConfirmModal.setDefaults({
  title: "Are you sure?",
  commit: "Yes",
  commitClass: "btn-primary",
  cancel: "No",
  cancelClass: "btn-outline-secondary cancel",
  focus: "commit",
  modalClass: "modal-confirm-dialog",
  fade: process.env.NODE_ENV !== "test",
});

// Initialise jQuery
import $ from "jquery";
import "jquery.are-you-sure";

// Cocoon
import "@nathanvda/cocoon";

// Report errors to Sentry
import * as Sentry from "@sentry/browser";
if (process.env.NODE_ENV === "production") {
  const sentryDsn = $('meta[name="sentry-dsn"]').attr("content");
  Sentry.init({ dsn: sentryDsn });
}

import { Alert } from "bootstrap";

$(() => {
  // Dismiss flash messages after 10 seconds
  if ($(".flash-messages .alert.auto-hide").length > 0) {
    setTimeout(() => {
      $(".flash-messages .alert.auto-hide").each((_, element) => {
        Alert.getOrCreateInstance(element).close();
      });
    }, 10000);
  }

  // Warn when leaving a page without submitting
  $("form").areYouSure({
    message:
      "Changes you have made to this form will not be saved; are you sure you want to leave this form?",
  });

  // Allow selecting or deselecting of elements in the 'site permissions' modal
  $(document).on("click", ".collapse-all-select-all, .site-select-all", (e) => {
    const target = $(e.target);
    const checked = target.data("checked");
    target
      .closest(".row")
      .find('input[type="checkbox"]')
      .prop("checked", checked);
  });

  // Allow selecting or deselecting options across all sites in the 'site permissions' modal
  $(document).on("change", '#collapse-all .row input[type="checkbox"]', (e) => {
    const target = $(e.target);
    const targets = target.data("target");
    $(targets).prop("checked", target.prop("checked"));
  });
  $(document).on("click", ".collapse-all-select-all", (e) => {
    const target = $(e.target);
    const checked = target.data("checked");
    const targets = target.closest("fieldset").data("target");
    $(targets).find('input[type="checkbox"]').prop("checked", checked);
  });

  $(document).on("click", 'a[data-toggle="ajax-modal"]', () => {
    $(document).on("ajax-modal-show", () => {
      var selectors = [
        Rails.linkDisableSelector,
        Rails.formEnableSelector,
      ].join(", ");
      $(selectors).each(function () {
        Rails.enableElement(this);
      });
    });
  });
});

// epiJS libraries
import "../vendor/ajax-modal";
import "@epigenesys/epi-js/dist/visibility_map";

// TODO: Is there a better way of handling this?
window.$ = $;

// Import stimulus controllers
import "../controllers";

// Support component names relative to the 'pages' directory
var pagesContext = require.context("pages", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(pagesContext);

import "controllers";
