import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="checkbox-toggle"
export default class extends Controller {
  static targets = ["checkbox", "content"];
  connect() {
    this.updateContent();
  }

  toggle() {
    this.updateContent();
  }

  // private

  updateContent() {
    if (this.checkboxTarget.checked) {
      // Show all the content if the checkbox is ticked
      this.contentTargets.forEach((content, i) => {
        content.classList.remove("d-none");
      });
    } else {
      // Hide all the content if the checkbox is not ticked
      this.contentTargets.forEach((content, i) => {
        content.classList.add("d-none");
      });
    }
  }
}
