import React from "react";

const FaIcon = ({ variant = "fa", icon, text, title, className }) => {
  if (text === undefined) {
    return (
      <i
        className={`${variant} fa-${icon}${className ? ` ${className}` : ""}`}
        title={title}
      ></i>
    );
  } else {
    return (
      <span
        className={`text-nowrap${className ? ` ${className}` : ""}`}
        title={title}
      >
        <i className={`${variant} fa-${icon} mr-1`} />
        {text}
      </span>
    );
  }
};

export default FaIcon;
